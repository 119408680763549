import React from 'react';
import styled from 'styled-components';

export default (props) => (
  <SVG
    {...props}
    width="29px"
    height="23px"
    viewBox="0 0 29 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon-x</title>
    <g
      id="🎈Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.800000012"
    >
      <g
        id="landing-/-footer-/-default"
        transform="translate(-253, -70)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <g id="SM-icons" transform="translate(101, 68)">
          <g id="x" transform="translate(152.8657, 2.2539)">
            <path
              d="M11.6467787,8.46863508 L18.932133,0 L17.2057373,0 L10.879865,7.35319683 L5.82740935,0 L0,0 L7.64030431,11.1193359 L0,20 L1.72649364,20 L8.40677773,12.2347742 L13.742541,20 L19.5699503,20 L11.6463547,8.46863508 L11.6467787,8.46863508 Z M9.28210899,11.2172998 L8.50798699,10.1100647 L2.34857343,1.29967954 L5.00036694,1.29967954 L9.9710854,8.40994153 L10.7452074,9.51717671 L17.2065527,18.7594282 L14.5547592,18.7594282 L9.28210899,11.2177239 L9.28210899,11.2172998 Z"
              id="icon-x"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

const SVG = styled.svg`
  #FC-design-system-atoms-icons {
    fill: #ffffffcc;
  }

  &:hover {
    #FC-design-system-atoms-icons {
      fill: #fff;
    }
  }
`;
