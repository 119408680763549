import React from 'react';
import styled from 'styled-components';

export default (props) => (
  <SVG
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 1024 1024"
  >
    <g id="Layer_1-2">
      <path
        fill="#fff"
        strokeWidth="0px"
        class="cls-1"
        d="M844.35,0H179.65C80.43,0,0,80.43,0,179.65v664.7c0,99.22,80.43,179.65,179.65,179.65h664.7c99.22,0,179.65-80.43,179.65-179.65v-46.61c-5.84.56-11.84.85-18,.85-109.94,0-171.83-91.57-174.5-206.16-.08-4.43-.05-8.83.05-13.2.06-2.73.17-5.45.31-8.15.07-1.45.14-2.81.25-4.32.11-1.52.22-3.03.34-4.55,3.96-50.59,20.24-95.61,47.12-128.92,16.7-20.68,36.77-36.43,59.52-46.97,19.99-10.06,48.08-15.62,70.98-15.62h.99c4.34,0,8.65.14,12.94.43v-191.48c0-99.22-80.43-179.65-179.65-179.65ZM836.92,244.45l-.81.18c-30.09,6.79-45.37,16.93-45.37,53.47h-.14l.14,438.37c2.58,28.86,18.09,37.77,45.37,43.93l.81.19v6.2h-210.03v-6.2l.81-.19c27.26-6.15,42.4-15.07,44.98-43.93v-426.46l-202.43,475.79h-11.16l-196.7-462.88v402.99c0,36.54,15.28,46.67,45.38,53.47l.8.18v6.25h-120.57v-6.27l.8-.18c30.1-6.79,45.44-16.93,45.44-53.47v-427.78c0-36.54-15.28-46.67-45.38-53.47l-.8-.18v-6.25h177.71l153.12,359.89,153.12-359.89h164.91v6.25Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        class="cls-1"
        d="M929.94,549.69c-4.24,74.89,36.75,138.18,94.06,155.05v-155.07h-94.03l-.03.02Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        class="cls-1"
        d="M932.05,528.01h91.95v-131.29c-4.55-1.25-9.42-1.92-14.6-2-46.31.96-73.91,56.43-77.35,133.29Z"
      />
    </g>
  </SVG>
);

const SVG = styled.svg`
  fill: #ffffffcc;

  &:hover {
    fill: #fff;
  }
`;
